import { Course } from "components/Card/interfaces";
import createStore from "zustand";

export type SearchStore = {
  // state
  currentRecommend: Course[];
  totalFilter: number;

  // actions
  setRecommendData: (items: Course[]) => void;
  setTotalFilter:(filter:number) => void;
};

export const useSearchStore = createStore<SearchStore>((set) => ({
  // state
  currentRecommend: [],
  totalFilter: 0,

  // actions
  setRecommendData(item) {
    set({ currentRecommend: item });
  },
  setTotalFilter(filter){
    set({totalFilter: filter})
  }
}));
