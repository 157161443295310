import Link from "next/link";
import { IconSearch } from "components/Icon/IconSearch";
import { trackEvent } from "lib/amplitude";
import { useRouter } from "next/router";
import { useIsSubdomain, useIsTenant } from "lib/host";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import { SkeletonRect, SkeletonWrapper } from "components/Skeleton/Skeleton";

function SearchHitsKeyword({ indexName, onClickKeyword, onSetValue }) {
  const [dataKeywords, setDataKeywords] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation("common");

  const fetchPopularKeywords = () => {
    fetch(`/api/analytics-algolia?indexName=${indexName}`)
      .then((res) => res.json())
      .then((res) => {
        if (res?.data?.searches) {
          setDataKeywords(res?.data?.searches.filter((a) => a.search));
          setIsLoading(false);
        } else {
          setDataKeywords(null);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    fetchPopularKeywords();
  }, []);

  return (
    <>
      {isLoading && !dataKeywords ? (
        <SkeletonWrapper>
          <div className="grid gap-12">
            {Array.from({ length: 1 }).map((a: number) => (
              <SkeletonRect key={a} className="h-24 w-full rounded-8 my-12" />
            ))}
            {Array.from({ length: 4 }).map((a: number) => (
              <SkeletonRect key={a} className="h-24 w-full rounded-8" />
            ))}
          </div>
        </SkeletonWrapper>
      ) : !isLoading && dataKeywords ? (
        <>
          {dataKeywords.length > 0 && (
            <div className="font-medium text-neutral-80 text-b1 mt-12">
              {t("trendingSearch")}
            </div>
          )}

          {dataKeywords.map((key, index) => (
            <ItemKeyword
              {...{ ...key, index, querySearch: key.search }}
              onClickKeyword={onClickKeyword}
              onSetValue={onSetValue}
              key={Math.random()}
            />
          ))}
        </>
      ) : (
        !isLoading &&
        !dataKeywords && (
          <>
            {/* <div className="font-medium text-neutral-80 text-b1 mt-12">
              {t("trendingSearch")}
            </div>
            <span className="text-left">No Data Search</span> */}
          </>
        )
      )}
    </>
  );
}
export default SearchHitsKeyword;

function ItemKeyword(param: any) {
  const isB2B = useIsTenant() || useIsSubdomain();
  const router = useRouter();

  return (
    <Link
      onClick={() => {
        param?.onSetValue(param.search);
        trackEvent({
          event: "explore_searchbar_suggestion_query_a",
          property: {
            page: router.pathname,
            suggested_query: param.search,
            index: param.index,
            query: param.querySearch,
          },
        });
        param?.onClickKeyword();
      }}
      href={{
        pathname: isB2B ? "/" : "/explore/search",
        query: { q: param.search },
      }}
    >
      <div className="text-b2 font-light flex items-center gap-8">
        <IconSearch size={16} /> {param.search}
      </div>
    </Link>
  );
}
