import { connectStateResults, connectHits } from "react-instantsearch-dom";
import Link from "next/link";
import { Course } from "components/Card/interfaces";
import { IconStar } from "components/Icon/IconStar";
import { formatRating, numberWithCommas } from "lib/helper";
import { CardNotFound } from "components/Card/CardNotFound";
import { useAuthStore } from "modules/Auth/authStore";
import { trackEvent } from "lib/amplitude";
import { useRouter } from "next/router";
import IconPlay from "components/Icon/IconPlay";
import { IconPlayCircle } from "components/Icon/IconPlayCircle";
import Button from "components/Button";
import { Image } from "components/Image/Image";
import { useEffect, useState } from "react";
import { ArrayObjectSortBy } from "lib/object";
import { SkeletonRect, SkeletonWrapper } from "components/Skeleton/Skeleton";
import { useSearchStore } from "./searchStore";
import useTranslation from "next-translate/useTranslation";
import { getSubDomain, useIsSubdomain, useIsTenant } from "lib/host";
import { useAccessCourseTenant } from "modules/Explore/exploreApi";
import { isBrowser } from "lib/ssr";
import { useGetCoursesByTenantSlug } from "modules/Tenant/homeTenantApi";

function SearchHitsCourse({ searchState, searchResults, indexName, ...props }) {
  const [recommendCourses, setRecommendCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allCourses, setAllCourses] = useState(null);
  const { t } = useTranslation("common");
  const isB2B = useIsTenant() || useIsSubdomain();
  const loginState = useAuthStore((state) => state.loginState);
  const { setRecommendData } = useSearchStore();
  const router = useRouter();

  /// Access Courses List
  const accessCourseApi = useAccessCourseTenant();
  const getCourses = useGetCoursesByTenantSlug();
  const currentUser = useAuthStore((state) => state.currentUser);

  // checking if the query length is >= 3
  // (since 3 is the minimum Algolia query length)
  const validQuery = searchState.query?.length >= 3;
  const totalData = searchResults?.nbHits;
  const dataCourses =
    searchResults?.hits.length > 0 && searchState.query && validQuery
      ? searchResults.hits
      : searchResults?.hits.length > 0 && !searchState.query
      ? searchResults.hits
      : null;

  let seeAllBtn = null;
  let searchResultsLms = null;
  if (props.showBtn) {
    seeAllBtn = (
      <Button
        variant="secondary"
        size="medium"
        isFull
        className="px-16 py-8 mt-12"
        onClick={props.onClickEnter}
      >
        Lihat semua
      </Button>
    );
    searchResultsLms = (
      <div className="flex items-center text-b1 font-light text-neutral-60">
        {totalData > 0 && <span>{totalData}</span>}
        <p className="mx-4">Hasil Pencarian untuk</p>
        <span className="font-medium text-neutral-80">
          "{props.searchValue}"
        </span>
      </div>
    );
  }

  const getCourseRecommend = () => {
    setAllCourses(null);
    setIsLoading(true);
    const subDomain = isBrowser()
      ? getSubDomain(window.location.hostname)
      : null;

    const referral = isB2B
      ? currentUser?.referral ?? subDomain
      : currentUser?.referral;

    if (referral && !["moodle", "example"].includes(referral)) {
      fetch(
        referral ? `/api/search-algolia?indexName=${referral}` : "/api/search"
      )
        .then((res) => res.json())
        .then((res) => {
          const data =
            ArrayObjectSortBy(res.data?.hits, "created_date", true) ?? [];
          const recommendCourses = data.slice(0, 4);
          setRecommendCourses(recommendCourses);

          if (recommendCourses.length) {
            setRecommendData(recommendCourses);
          }

          setTimeout(() => {
            setIsLoading(false);
          }, 200);
        });
    }
  };

  useEffect(() => {
    if (!searchState.query) {
      setAllCourses(null);
      setIsLoading(true);
      const subDomain = isBrowser()
        ? getSubDomain(window.location.hostname)
        : null;

      const referral = isB2B
        ? currentUser?.referral ?? subDomain
        : currentUser?.referral;

      if (referral && !["moodle", "example"].includes(referral)) {
        getCourses.doFetch(
          {
            slug: referral,
            // @ts-ignore
            popular: 1,
          },
          {
            onSuccess(data) {
              
              // Get first 3 courses
              setAllCourses(data?.data.slice(0,3));
            },
          }
        );
      }
      return
    }

    if (dataCourses && dataCourses.length > 0) {
      const newData = isB2B
        ? loginState === "LOGIN"
          ? dataCourses
          : dataCourses.filter((a) => !a.tenant)
        : dataCourses.filter((a) => !a.is_institution_business);

      /// Run normal function without checking
      /// allowed course or not when user is anonymous
      if (!currentUser?.referral) {
        if (newData && newData.length > 0) {
          setAllCourses(newData);
        } else {
          getCourseRecommend();
        }
        return;
      }
      /// Fetch access course API to get
      /// list of allowed course
      accessCourseApi.doFetch(
        {
          // @ts-ignore
          slug: currentUser?.referral,
        },
        {
          onSuccess(data) {
            const listAccessCourses = data?.data;
            const userId = currentUser?.id;
            const listForbiddenCourses = [];
            /// Generate forbidden course where
            /// userId != id from API
            listAccessCourses.forEach((course) => {
              if (course.user != userId) {
                const listCoursesSlug = course.courses?.map(
                  (course) => course.slug
                );
                listForbiddenCourses.push(...listCoursesSlug);
              }
            });
            /// Hide course where it listed on forbidden courses
            if (listForbiddenCourses.length > 0) {
              const showedCourses = newData.filter(
                (course) => !listForbiddenCourses.includes(course.slug)
              );
              // console.log("showedCourses:",showedCourses)
              setAllCourses(showedCourses);
            } else {
              // console.log("no forbidden course")
              if (newData && newData.length > 0) {
                setAllCourses(newData);
              } else {
                getCourseRecommend();
              }
            }
          },
          onError(err) {
            if (newData && newData.length > 0) {
              setAllCourses(newData);
            } else {
              getCourseRecommend();
            }
          },
        }
      );
    } else {
      getCourseRecommend();
    }
  }, [dataCourses, searchState.query]);

  useEffect(() => {
    if (searchState.query) {
      trackEvent({
        event: "explore_searchbar_suggestion_a",
        property: {
          page: router.pathname,
          query: searchState.query,
        },
      });
    }
  }, [searchResults?.hits]);

  return (
    <>
      {!allCourses ? (
        <div className="flex flex-col gap-24">
          <CardNotFound
            title="Kursus Tidak Ditemukan"
            message="Coba cari kursus dengan kata kunci lain"
            className="!gap-16 bg-slated_grey2 rounded-8"
            useSmallIcon
          />

          {(isLoading || recommendCourses.length > 0) && (
            <div className="grid gap-12 hg-max:max-h-[50vh] hg-max:overflow-y-scroll">
              <div className="text-b1 font-medium text-neutral-80">
                {t("recommendationForYou")}
              </div>

              {isLoading ? (
                <SkeletonWrapper>
                  <div className="grid gap-12">
                    {[1, 2, 3, 4].map((el) => (
                      <div
                        className="flex flex-row gap-8"
                        key={el + Math.random()}
                      >
                        <SkeletonRect className="h-80 w-140 rounded-8" />

                        <div className="flex flex-col justify-center items-center w-[calc(100%-148px)] gap-4">
                          <SkeletonRect className="h-16 w-full" />
                          <SkeletonRect className="h-16 w-full" />
                          <SkeletonRect className="h-16 w-full" />
                        </div>
                      </div>
                    ))}
                  </div>
                </SkeletonWrapper>
              ) : (
                recommendCourses &&
                recommendCourses.map((hit: Course, index: any) => (
                  // @ts-ignore
                  <ItemCourse
                    key={Math.random() + "--" + hit.id}
                    {...{
                      ...hit,
                      index,
                      closeModal: props.closeModal,
                      query: searchState.query ?? "",
                    }}
                  />
                ))
              )}
            </div>
          )}
        </div>
      ) : (
        <>
          {searchResultsLms}

          {!searchState.query && allCourses.length > 0 && (
            <div className="font-medium text-neutral-80 text-b1 mt-16 md:mt-0">
              {t("popularCourses")}
            </div>
          )}

          {allCourses.map((hit: Course, index: any) => (
            // @ts-ignore
            <ItemCourse
              key={Math.random() + "-" + hit.id}
              {...{
                ...hit,
                index,
                closeModal: props.closeModal,
                query: searchState.query ?? "",
              }}
            />
          ))}

          {seeAllBtn}
        </>
      )}
    </>
  );
}
export default connectStateResults(SearchHitsCourse);

function ItemCourse({ ...props }) {
  const loginState = useAuthStore((state) => state.loginState);
  const router = useRouter();
  const closeModal = () => (props.closeModal ? props.closeModal() : null);
  const isLMS = router.pathname.includes("/course/[courseSlug]");
  const isB2B = useIsTenant() || useIsSubdomain();
  const institutionName = props?.institution?.name ?? props?.institution?.split("||")[0]
  return (
    <Link
      onClick={() => {
        trackEvent({
          event: "explore_searchbar_suggestion_course_a",
          property: {
            page: router.pathname,
            course_name: props.name,
            course_slug: props.slug,
            institution_name: props.institution.split("||")[0] || "",
            index: props.index,
            query: props.query,
          },
        });
        closeModal();
      }}
      href={
        loginState == "LOGIN" && isLMS
          ? {
              pathname: "/r/[page]",
              query: {
                page: `/course/${props?.slug}`,
              },
            }
          : loginState == "LOGIN"
          ? {
              pathname: "/course/[courseSlug]",
              query: { courseSlug: props?.slug },
            }
          : {
              pathname: "/login",
              query: {
                url: encodeURIComponent(`/course/${props?.slug}`),
              },
            }
      }
    >
      <div className="flex flex-row w-full gap-8 cursor-pointer">
        {props?.image_cover_url ? (
          <div className="h-80 w-[140px] relative">
            <Image
              src={props?.image_cover_url}
              className="object-cover object-center rounded-8"
              width={140}
              height={80}
            />

            <div className="absolute rounded-8 w-[140px] h-80 top-0 flex justify-center items-center">
              <IconPlayCircle
                size={32}
                classes="m-auto"
                isBgCustomized
              />
            </div>
          </div>
        ) : (
          <div className="w-140 h-80 rounded-8 bg-neutral-20"></div>
        )}
        <div className="grid w-[calc(100%-148px)] justify-start gap-4 text-b2 items-center">
          <div className="font-light line-clamp-1">
            {institutionName}
          </div>
          <div className="text-neutral-80 font-medium line-clamp-1">
            {props?.name}
          </div>

          <div className="flex gap-8 justify-start items-center text-c1 font-light">
            {typeof props?.review_summary?.star_avg === "number" &&
            props?.review_summary?.star_avg !== 0 &&
            !isB2B ? (
              <>
                <div className="flex items-center justify-center">
                  <IconStar size={12} fill color="#EFB443" />
                  <div className="ml-2 text-c1">
                    {formatRating(
                      //  @ts-ignore
                      props?.review_summary?.star_avg.toFixed(1)
                    )}
                  </div>
                </div>
                |
              </>
            ) : (
              <></>
            )}
            {props?.total_learners !== 0 && !isB2B && (
              <div>{`${numberWithCommas(props?.total_learners)} pelajar`}</div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}
